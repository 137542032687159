/**
 * 模块名称: 操作日志
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  DatePicker,
  Input,
  Button,
  Alert,
  Modal
} from 'antd'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  sort: '', //addTime
  sortMethod: '' //desc
}

const OperationLog = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match } = props

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const intervalRef = useRef()
  const search = parseSearch(props.location.search)

  useEffect(() => {

    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogOptList()
    return () => clearTimeout(intervalRef.current)
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取操作日志列表
  const getLogOptList = () => {
    let id = setTimeout(() => {
      api.getLogOptList(searchParamsTmp).then(res => {
        //console.log(res)
        setList(res.list)
        setCount(res.count)
        setLoading(false)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogOptList()
  }

  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    //currentPage = 1
    validateFields((err, vals) => {
      //console.log(vals)
      if (vals !== undefined) {
        if (vals.module !== undefined) searchParamsTmp.module = vals.module
        if (vals.userAccount !== undefined) searchParamsTmp.userAccount = vals.userAccount
        if (vals.userIp !== undefined) searchParamsTmp.userIp = vals.userIp
        if (vals.content !== undefined) searchParamsTmp.content = vals.content
        if (vals.optTime !== undefined) {
          searchParamsTmp.optTimeBegin = vals.optTime ? vals.optTime[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.optTimeEnd = vals.optTime ? vals.optTime[1].format('YYYY-MM-DD') : ''
        }
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getLogOptList()
      }
    })
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      searchParamsTmp.sort = sorter.columnKey
      searchParamsTmp.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      searchParamsTmp.sort = ''
      searchParamsTmp.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp.limit = pageSize
    searchParamsTmp.page = currentPage
    getLogOptList()
  }

  const onShowModal = (record, text) => {
    //console.log(record, text)
    Modal.info({
      title: '请求数据',
      content: (
        <div>
          <p>{text}</p>
        </div>
      ),
      onOk() {

      }
    })
  }

  return (
    <>
      <div className="search-doc-wrap search-office-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="模块" style={{ width: 280 }}>
              {getFieldDecorator('module', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="登录账号" style={{ width: 280 }}>
              {getFieldDecorator('userAccount')(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="IP" style={{ width: 280 }}>
              {getFieldDecorator('userIp', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="请求数据" style={{ width: 280 }}>
              {getFieldDecorator('content', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="操作时间" style={{ width: 320 }} >
              {getFieldDecorator('optTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
          scroll={{ x: 1800 }}
        >
          <Column title="模块名称" dataIndex="module" fixed="left" width={260} />
          <Column title="登录账号" dataIndex="userAccount" fixed="left" width={100} />
          <Column title="请求数据" dataIndex="content" width={1100} render={(text, record) => (
            <span onClick={() => onShowModal(record, text)} style={{ cursor: 'pointer', color: '#1890ff' }}>{text}</span>
          )} />
          <Column title="IP" dataIndex="userIp" fixed="right" width={180} />
          <Column title="操作时间" dataIndex="updateTime" fixed="right" sorter width={180} />
        </Table>
      </div>
    </>
  )
}

export default Form.create()(OperationLog)
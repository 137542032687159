/**
 * 模块名称: 邮件日志=>详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Divider, message
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const Details = (props) => {
  const { location, history } = props

  const [detailList, setDetailList] = useState([])
  const [content1, setContent1] = useState([])
  const [content2, setContent2] = useState([])

  useEffect(() => {
    const search = parseSearch(location.search)
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getLogMailDetail({ id: search.id }, true).then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
  
        setDetailList(data)
  
        let arr = data.content.split('：');
        setContent1(arr[0])
  
        let content2 = ''
        for(let i = 0; i < arr.length; i++){
          if(i == 0){
            continue;
          }
  
          content2 += arr[i]
        }
        setContent2(content2)
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  return (
    <>
      <div className="mail-detail">
        <h1>{detailList.title}</h1>
        <div className="mail-title">
          <div className="mail-name">发件人：系统管理员</div>
          <div className="mail-name">收件人：{detailList.recevier}</div>
          <div className="mail-name">发件时间：{detailList.updateTime}</div>
        </div>
        <Divider />
        <div className="mail-content">{content1}：</div>
        <div className="mail-content" style={{whiteSpace: 'normal'}} dangerouslySetInnerHTML={{__html: content2}} />
        {/* <div className="mail-content2">{content2}</div> */}
      </div>
    </>
  )
}

export default Details
/**
 * 模块名称: 帮助中心管理
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ThirdPartyMgt from './ThirdPartyMgt'
import HelpListDetail from './module/HelpListDetail'
import FeedbackDetail from './module/FeedbackDetail'
import './assets/style.scss'

const HelpCenterMgtIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/ThirdPartyMgt'} component={ThirdPartyMgt} />
        <Route path={match.path + '/detail'} component={HelpListDetail} />
        <Route path={match.path + '/details'} component={FeedbackDetail} />
        <Route component={ThirdPartyMgt} />
      </Switch>
    </div>
  )
}

export default HelpCenterMgtIndex;

/**
 * 模块名称: 导入列表
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Drawer,
  Table,
  Alert,
  Form,
  Button,
  Input,
  DatePicker,
  Icon,
  message,
  Tooltip
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker;
let pageSize = 10
let currentPage = 1

let modulePageSize = 10

let filter = {
  userName: '',
  userId: '',
  addTimeBegin: '',
  addTimeEnd: '',
  sort: '', //addTime
  sortMethod: '' //desc
}

const Tolead = (props) => {
  const { match, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  //const [authList, setAuthList] = useState([])
  const [moduleLoading, setModuleLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [moduleCount, setModuleCount] = useState(0)//eslint-disable-line
  
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      userName: '',
      userId: '',
      addTimeBegin: '',
      addTimeEnd: '',
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getToleadList()
    //api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const getToleadList = () => {
    setLoading(true)
    let params = {
      limit: pageSize,
      page: currentPage,
      userName: filter.userName,
      userId: filter.userId,
      addTimeBegin: filter.addTimeBegin,
      addTimeEnd: filter.addTimeEnd,
      sort: filter.sort,
      sortMethod: filter.sortMethod
    }
    api.uploadList(params).then(data => {
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      filter.sort = sorter.columnKey
      filter.sortMethod = sorter.order == 'ascend' ? 'asc' : 'desc'
    } else {
      filter.sort = ''
      filter.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize

    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getToleadList()
  }

  // 查询
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        history.replace(match.path)
        currentPage = 1
        filter.userName = vals.admin ? vals.admin : ''
        filter.userId = vals.adminId ? vals.adminId : ''
        // filter.addTimeBegin = vals.inpicker[0].format('YYYY-MM-DD HH:mm:ss')
        filter.addTimeBegin = vals.inpicker ? vals.inpicker[0].format('YYYY-MM-DD') : ''
        filter.addTimeEnd = vals.inpicker ? vals.inpicker[1].format('YYYY-MM-DD') : ''
        getToleadList()
      }
    })
  }

  // 重置
  const onReset = (e) => {
    e.preventDefault()
    filter.userName = ''
    filter.userId = ''
    filter.addTimeBegin = ''
    filter.addTimeEnd = ''
    // filter.sort = ''
    // filter.sortMethod = ''
    resetFields()
    history.replace(match.path)
    currentPage = 1
    getToleadList()
  }

  //下载
  const onShowDialog = (id) => {
    api.goToUpload({
      id: id
    })
      .then(data => {
        setLoading(false)
        message.success('数据下载已成功，请到下载列表下载。', 3)
      }).catch(() => {
        setLoading(false)
        message.error('数据下载失败', 3)
      })
  }

  //打开失败列表
  const onManage = (data) => {   
    setVisible(true)
    setModuleList(data.failedReason)
    setModuleLoading(false)
  }

  const onShowSizeChangeFun = (current, size) => {
    let bak = moduleList.slice()
    setModuleList([])
    modulePageSize = size
    setModuleList(bak)
  }

  return (
    <>
      <div className="search-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <FormItem label="操作人">
            {getFieldDecorator('admin', {
            })(
              <Input placeholder="请输入" />
            )}
          </FormItem>
          <FormItem label="操作人ID">
            {getFieldDecorator('adminId', {
            })(
              <Input placeholder="请输入操作人ID" />
            )}
          </FormItem>
          <FormItem label="导入时间">
            {getFieldDecorator('inpicker', {
            })(
              <RangePicker allowClear={false} />
            )}
          </FormItem>
          <FormItem className="btns" label=" ">
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          // scroll={{x: 1400}}
          onChange={onChangeTable}
        >
          <Column title="文件名称" dataIndex="fileName" width={360} />
          <Column title="导入模块" dataIndex="module" />
          <Column title="操作人" dataIndex="userName" />
          <Column title="操作人ID" dataIndex="userId" />
          <Column title="导入时间" dataIndex="addTime" sorter />
          <Column title="导入总条数" dataIndex="total" />
          <Column title="失败条数"  
            dataIndex="failedCount"
            render={(text, record) => <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => onManage(record)}>{text}</span>} 
          />
          <Column title="下载链接"
            key="fileUrl"
            render={(text, record) =>
              <Auth auths={text.operateAuth} code="operate">
                <Tooltip title="下载" placement="bottom">
                  <Icon type="cloud-download" style={{ color: '#1890ff' }} onClick={() => onShowDialog(text.id, 'up')} />
                </Tooltip>
              </Auth>
            } />
        </Table>
      </div>
      <Drawer
        title="失败列表"
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Table
          dataSource={moduleList}
          rowKey="row"  
          className="sort-table"
          loading={moduleLoading}
          pagination={{
            pageSize: modulePageSize,
            // total: moduleCount,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
            showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: onShowSizeChangeFun
          }}
        >
          {/* <Column title="行数" dataIndex={`{"row"行"col"列}`}/> */}
          <Column title="行数" dataIndex="row" />
          <Column title="" dataIndex="col" />
          <Column title="失败原因" dataIndex="msg"/>
        </Table>
      </Drawer>
    </>
  )
}

export default Form.create()(Tolead)

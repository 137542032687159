/**
 * 模块名称: 登录
 * @author xuzhongyuan@372163.com
 * 功能: 记住账号
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { Form, Icon, Input, Button, Checkbox, message } from 'antd'
import api from '@/api'
import './assets/style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '@/global/reducer/userInfo'
import logo from './assets/logo.png'
import bg_img from './assets/bg_img.png'
import { parseSearch } from '@/utils'

function Login({ form, history, location }) {
  const dispatch = useDispatch()
  const [fetching, setFetching] = useState(false)
  const { getFieldDecorator, validateFields, setFieldsValue,getFieldValue} = form
  const account = localStorage.getItem('username')
  const { syslogo, sysname } = useSelector(state => state.config)
  const search = parseSearch(location.search)

  useEffect(() => {
    if (account) {
      setFieldsValue({
        username: account,
        remember: true
      })
    }

    // oa自动登录
    if (search.code) {
      const loading = message.loading('正在自动登录中...', 0)
      api.useTokenLogin({
        code: search.code
      }).then(res => {
        loading()
        localStorage.setItem('token', res.token)
        dispatch(setUserInfo(res))
        history.push('/index')
      }).catch(() => loading())
    }
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    validateFields((err, values) => {
      if (!err) {
        // const phoneReg = /0?(13|14|15|18|17)[0-9]{9}/
        // const emailReg = /\w+@\w+/
        setFetching(true)
        api.login({
          account: values.username,
          password: values.password
        }, true)
          .then(res => {
            setFetching(false)
            localStorage.setItem('token', res.token)
            if (values.remember) {
              localStorage.setItem('username', values.username)
            } else {
              localStorage.removeItem('username')
            }
            dispatch(setUserInfo(res))
            history.push('/index')
          })
          .catch(() => setFetching(false))
      }
    })
  }

  // 账号失焦
  const inputUserValBlur = (e) => {
    if (e.target.value.trim() !== '') {
      api.checkUserByAccount({
        account: e.target.value
      }).then(res => {

      }).catch(err => {

      })
    }
  }

  // 密码获取焦点
  const inputPassWdFocus = (e) => {
    let userName = getFieldValue('username');
    if (userName.trim() !== '') {
      api.checkUserByAccount({
        account: userName
      }).then(res => {

      }).catch(err => {

      })
    }
  }

  return (
    <section className="login-body">
      {/* 背景 */}
      <section className="bg">
        <div className="bg-content">
          <img src={bg_img} alt="bg_img" className="bg_img" />
        </div>
        {/* 底部 */}
        <section className="footer">
          <div className="footer-content">
            <p>版权所有：北京全时天地在线网络信息股份有限公司　京ICP备09035321号　京公网安备11010502030777号</p>
          </div>
        </section>
      </section>
      {/* 表单 */}
      <section className="login">
        <section className="login_header">
          <div className="header_content">
            <h1><img src={syslogo || logo} alt="logo" className="login_header_logo" /><span className="sys-name">{sysname || '全时天地在线'}</span></h1>
          </div>
        </section>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: '请输入用户名' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="请输入用户名"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入密码' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="请输入密码"
                onFocus={inputPassWdFocus}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked'
            })(<Checkbox >记住账号</Checkbox>)}
            <a className="login-form-forgot" href="/forgetPassWord">忘记密码？</a>
            <Button type="primary" loading={fetching} htmlType="submit" className="login-form-button">登录</Button>
            <div className="no-use-ie">建议使用360浏览器极速模式、搜狗浏览器高速模式、QQ浏览器极速模式、chrome等浏览器访问系统。请点击地址栏右侧“闪电”图标切换浏览器模式</div>
          </Form.Item>
        </Form>
      </section>
    </section>
  )
}

export default Form.create()(Login)
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import MailLogs from './MailLogs'
import Details from './Details'

const MailLogIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route component={MailLogs} />
    </Switch>
  )
}

export default MailLogIndex
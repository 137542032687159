/**
 * 模块名称: 帮助中心详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { parseSearch } from '@/utils'
import DetailsComp from '@/components/Details'
import urls from '@/api/urls'
import api from '@/api'
import { 
  Radio,
  Icon
} from 'antd'

const HelpCenterDetail = (props) => {
  const { history } = props
  const search = parseSearch(props.location.search)
  const [radioFlag, setRadioFlag] = useState(false)//eslint-disable-line
  const [showRadio, setshowRadio ] = useState(false)
  const [isUserFul, setIsUserFul] = useState('')
  const [data, setData] = useState({
    id: '',
    title: '',
    crateTime: '',
    createBy: '',
    content: '',
    number: '',
    file: []
  })

  useEffect(() => {
    SupportQuestionDetail()
  }, [])

  const SupportQuestionDetail = () => {
    api.getSupportQuestionDetail({ id: search.id }, true).then(data => {
      if (data.isFullAuth == '0') {//eslint-disable-line
        setshowRadio(true)
      }
      if(JSON.stringify(data.isUserFul)) {
        // console.log("data.isUserFul",(data.isUserFul)-1+'')
        setIsUserFul((data.isUserFul)-1+'')
      }
      setData({ 
        ...data,
        crateTime: data.addTime,
        createBy: data.userName,
        content: data.content,
        number: data.readNumber
      })
    })
  }

  const onChangeRadio = (e) => {
    // console.log("e",e)
    api.setSupportQuestionUseful({
      id: search.id,
      isUseFul: e.target.value,
    }).then((data) => {
      // setRadioFlag(true)
      SupportQuestionDetail()
    })
  }

  // const onCancel = () => {
  //   history.push({
  //     pathname: '/helplist',
  //     state: { 'tabActiveKey': '1' }
  //   })
  // }

  return (
    <>
      <DetailsComp
        data={data}
        onClose={history.goBack}
        url={data.file && data.file.length ? `${urls.downloadQuestionAttachment.url}?id=${data.id}` : null}
      /> 
      <div className="help-detail-con">
      { showRadio ? 
       <div className="help-detail">
          {
          <Radio.Group value={isUserFul} size="large" onChange={onChangeRadio} disabled={radioFlag}>
            <Radio.Button value={'0'}><Icon type="like" /></Radio.Button>
            <Radio.Button value={'1'}><Icon type="dislike" /></Radio.Button>
          </Radio.Group>
          }       
        </div> : null }   
      </div>
    </>
  )
}

export default HelpCenterDetail
/**
 * 模块名称: 帮助中心列表 -> 反馈列表
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker
} from 'antd'
import { parseSearch } from '@/utils'
// import Auth from '@/components/AuthMiddleware'
import { Link } from 'react-router-dom'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let Key = ''

let filter = {
  title: '',
  status: '',
  userName: '',
  searchCreationTimeBegin: '',
  searchCreationTimeEnd: '',
  sort: '', //addTime
  sortMethod: '' //desc
}

let status = [{
  id: 1,
  name: '未回复'
}, {
  id: 2,
  name: '已回复'
}]

const FeedbackList = (props) => {
  const { match, location, history } = props.props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [statusVal, setStatusVal] = useState([])

  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '1'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      title: '',
      status: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: '',
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getList()
    onStatus()
  }, [Key])

  const getList = () => {
    setLoading(true)
    api.getSupportFeedbackList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      filter.sort = sorter.columnKey
      filter.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      filter.sort = ''
      filter.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize + '&activeKey=' + 2)
    getList()
  }

   // 状态
   const onStatus = () => {
    setStatusVal(status)
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=2')
        filter = {
          ...filter,
          ...vals,
          searchCreationTimeBegin: vals.searchCreationTime ? vals.searchCreationTime[0].format('YYYY-MM-DD') : '',
          searchCreationTimeEnd: vals.searchCreationTime ? vals.searchCreationTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      title: '',
      status: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: ''
    }
    currentPage = 1
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=2')
    getList(currentPage)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div className="helpCenter-con">
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo helpCenter-form">
        <div>
          <div>
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="问题" labelCol={{ span: 4 }}>
                  {getFieldDecorator('title')(<Input placeholder="请输入问题" />)}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="提问人" labelCol={{ span: 6 }}>
                  {getFieldDecorator('userName')(<Input placeholder="请输入提问人" />)}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="状态" labelCol={{ span: 4 }}>
                  {getFieldDecorator('status')(
                    <Select placeholder="全部">
                      {statusVal&&statusVal.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="创建日期" labelCol={{ span: 6 }}>
                  {getFieldDecorator('searchCreationTime')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        //  scroll={{ x: 2200 }}
        onChange={onChangeTable}
   >
     <Column title="问题"
      dataIndex="title"
      ellipsis={true} 
      width="30%"
      render={(text, record) =><Link to={`/helplist/details?id=${record.id}`}>{text}</Link>}
    />
     <Column title="提问人" dataIndex="userName" />
     <Column title="浏览次数" dataIndex="readNumber" />
     <Column title="有帮助" dataIndex="useFul" />
     <Column title="没帮助" dataIndex="useLess" />
     <Column title="创建时间" dataIndex="addTime" sorter />

     <Column title="状态" dataIndex="feedBackState" render={(text, record) => {
        if (text == 1) {//eslint-disable-line
          return '未回复'
        } else if (text == 2) {//eslint-disable-line
          return '已回复'
        } else {
          return ''
        }
      }} />
   </Table>
    </div>
  );
}

export default Form.create()(FeedbackList)

/**
 * 模块名称: 帮助中心列表
 * @author zhuyan@372163.com
 */
import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import HelpCenter from './HelpCenter'
import  FeedbackList from './FeedbackList'
import { parseSearch } from '@/utils'

let pageSize = 10
// let currentPage = 1
let tabActiveKey = "1"

const ThirdPartyMgt = (props) => {
  const { history, match } = props
  const { TabPane } = Tabs
  const search = parseSearch(props.location.search)
  // const [tabActiveKey, setTabActiveKey] = useState('1')

  useEffect(() => {
    // currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    if(search.activeKey){
      tabActiveKey = search.activeKey
      onTabClick(tabActiveKey)
    }else{
      tabActiveKey = '1'
    }
  }, [])

  const onTabClick = (e) => {
    tabActiveKey = e
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=' + tabActiveKey)
  }

  return (
    <div>
      <div className="one-main">
        <Tabs activeKey={tabActiveKey} onTabClick={onTabClick}>
          <TabPane tab="帮助中心" key="1">
            <HelpCenter props={props}/>
          </TabPane>
          <TabPane tab="反馈列表" key="2">
            <FeedbackList props={props}/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default ThirdPartyMgt;

/**
 * 模块名称: 队列列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  DatePicker
} from 'antd'
import { parseSearch } from '@/utils'
import Export from '@/components/Export'
import moment from 'moment'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1

let filter = {
  id: '',
  task_type: '',
  params: '',
  err_msg: '',
  addTime: '',
  status: ''
}

let selectedIds = ''
let params = {}

const Task = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [taskypeList, setTaskypeList] = useState([])
  const [expand, setExpand] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    filter = {
      id: '',
      task_type: '',
      params: '',
      err_msg: '',
      addTime: '',
      status: ''
    }
    getList()
    getTaskType()
    setStatusList([{id:'0', name:'未处理'}, {id:'1', name:'处理中'}, {id:'2', name:'成功'}, {id:'-1', name:'处理失败'}, {id:'-2', name:'处理失败并终止'}])
  }, [])

  const getList = () => {
    setLoading(true)
    api.getKingdeeQueueList({ ...filter, limit: pageSize, page: currentPage })
    .then(data => {
      setLoading(false)
      for(var i = 0; i < data.list.length; i++){
        //data.list[i].operateAuth = ['edit', 'delete', 'browse-detail']
      }
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  const getTaskType = () => {
    api.getKingdeeTaskTypeList({limit: global.paramsLimit, page: currentPage })
    .then(data => {
      setTaskypeList(data)
    })
    .catch(() => setLoading(false))
  }


  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        filter = { ...filter, ...vals }
        currentPage = 1
        history.replace(match.path)
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    filter.addTimeMin = ''
    filter.addTimeMax = ''
    filter = {
      id: '',
      task_type: '',
      params: '',
      err_msg: '',
      addTime: '',
      status: ''
    }
    setStatusVal(undefined)
		setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      id: data.id ? data.id : undefined,
      task_type: data.task_type ? data.task_type : undefined,
      params: data.params ? data.params : undefined,
      err_msg: data.err_msg
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onResetQueue = (data) => {
    api.kingdeeResetQueue({
      id: data.id
    }).then(res => {
      message.success('重试成功')
      getList()
    })
  }

  const onJumpLog = (data) => {
    history.push('/kingdee/log?taskId=' + data.id)
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.created_timeMin = ''
      filter.update_timeMax = ''
    } else {
      filter.created_timeMin = moment(data[0]).format('YYYY-MM-DD')
      filter.update_timeMax = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  return (
    <>
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="任务ID" labelCol={{span: 6}}>
                  {getFieldDecorator('id', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="任务类型" labelCol={{span: 6}}>
                  {getFieldDecorator('task_type')(
                    <Select placeholder="请选择">
                      {taskypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="请求信息" labelCol={{span: 6}}>
                  {getFieldDecorator('params', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="失败原因" labelCol={{span: 6}}>
                  {getFieldDecorator('err_msg', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={`search-btns ${expand ? '' : 'hide'}`}>
              <Col span={9}>
                <FormItem label="时间范围" labelCol={{span: 6}}>
                {getFieldDecorator('addTime', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="状态" labelCol={{span: 6}}>
                  {getFieldDecorator('status')(
                    <Select placeholder="请选择">
                      {statusList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
              </Col>
              <Col span={3}>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" style={{marginLeft: 8, marginRight: 8}} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>

          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert className="count-alert" message={
        <>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      }/>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 1400}}
        onChange={onChangeTable}
      >
        <Column title="任务ID" dataIndex="id" />
        <Column title="任务类型" dataIndex="task_type_cn" />
        <Column title="开始时间" dataIndex="created_time" />
        <Column title="完成时间" dataIndex="success_time" />
        <Column title="请求信息" dataIndex="params" />
        <Column title="失败原因" dataIndex="err_msg" />
        <Column title="状态" dataIndex="statusCn" />
        <Column title="操作"
          key="set" 
          fixed="right"
          width={150}
          render = {(record) => {
            return (
              record.status == -2 ? 
              <>
                <span className="hrefspan" onClick={() => onResetQueue(record)}>重试</span> 
                &nbsp;&nbsp;&nbsp;<span className="hrefspan" onClick={() => onJumpLog(record)}>日志</span>
              </>
              : 
                <span className="hrefspan" onClick={() => onJumpLog(record)}>日志</span>
            )
          }}
        />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getRefundExportTpl"
        fieldsUrl="getRefundTplItems"
        saveUrl="saveRefundTpl"
        exportUrl="exportRefund"
        method="export"
        parame={{ ExportTyp: 'refund', id: selectedIds, ...filter }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Task)
/**
 * 模块名称: 模块名称: 帮助中心列表 -> 我要提问
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import api from '@/api'
import {
  Form,
  Input,
  message
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import Editor from 'wangeditor'

const FormItem = Form.Item

const AddSupport = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const { visible, setVisible } = props//eslint-disable-line
  const [saveLoading, setSaveLoading] = useState(false)
  const [editorHtml, setEditorHtml] = useState('')//eslint-disable-line
  const [editorText, setEditorText] = useState('')//eslint-disable-line
  const [content, setContent] = useState('')//eslint-disable-line

  const contentRef = useRef(null)

  useEffect(() => {
    setTimeout(initEditor(),100)
  }, [])

  //富文本
	const initEditor = () => {
		let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
		dealEditor(editor)
		editor.create()
	}
	//富文本数据配置
	const dealEditor = (editor) => {
		editor.customConfig.showLinkImg = false
		editor.customConfig.uploadImgServer = '/api/File/upload'
		editor.customConfig.uploadFileName = 'file'
		editor.customConfig.uploadImgHeaders = {
			token: localStorage.getItem('token')
		}
		// 自定义菜单配置
    editor.customConfig.menus = [
			'head',  // 标题
			'bold',  // 粗体
			'fontSize',  // 字号
			'fontName',  // 字体
			'italic',  // 斜体
			'underline',  // 下划线
			'strikeThrough',  // 删除线
			'foreColor',  // 文字颜色
			'backColor',  // 背景颜色
			'link',  // 插入链接
			'list',  // 列表
			'justify',  // 对齐方式
			'quote',  // 引用
			'image',  // 插入图片
			'table',  // 表格
			'code',  // 插入代码
			'undo',  // 撤销
			'redo'  // 重复
		]
		//插入视频 : 'video'
		editor.customConfig.onchange = (html) => {
			//console.log(html)
			setEditorHtml(html)
			setEditorText(editor.txt.text())
			setFieldsValue({
				content: html
			})
		}
		editor.customConfig.uploadImgHooks = {
			before(xhr, editor, files) {
				//console.log(xhr, editor, files)
			},
			success(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			fail(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			error(xhr, editor, result) {
				// console.log(xhr, editor, result)
			},
			timeout(xhr, editor){
				// console.log(xhr, editor)
			},
			customInsert(insertImg, result, editor){
				//console.log(insertImg, result, editor)
				var url = result.data.full
        insertImg(url)
			}
		}
	}

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 22 },
    }
  }

  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        setSaveLoading(true)
        api.addSupportFeedback({
          title: vals.title,
          content: vals.content,
        })
          .then(() => {
            setSaveLoading(false)
            setVisible(false)
            message.success('提交成功，系统管理员会尽快回复，请耐心等待')
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  return (
    <div>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="标题">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: '请输入标题' }]
          })(
            <Input placeholder="请输入标题"  maxLength={30} />
          )}
        </FormItem>
        <FormItem label="内容">
          {getFieldDecorator('content', {initialValue: content,
            rules: [{ required: true, message: '请输入问题' }]
          })(<div className="wangEditor-container" ref={contentRef}></div>)}
        </FormItem>
        <BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
      </Form>
    </div>
  );
}

export default Form.create()(AddSupport)


/**
 * 模块名称: 登录日志
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  DatePicker,
  Input,
  Button,
  Alert
} from 'antd'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  sort: '', //addTime
  sortMethod: '' //desc
}

const LoginLog = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match } = props

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const intervalRef = useRef()
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogLogList()
    return () => clearTimeout(intervalRef.current)
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取登录日志列表
  const getLogLogList = () => {
    let id = setTimeout(() => {
      api.getLogLogList(searchParamsTmp).then(res => {
        //console.log(res)
        setList(res.list)
        setCount(res.count)
        setLoading(false)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogLogList()
  }

  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    //currentPage = 1
    validateFields((err, vals) => {
      if (vals !== undefined) {
        if (vals.userName !== undefined) searchParamsTmp.userName = vals.userName
        if (vals.userAccount !== undefined) searchParamsTmp.userAccount = vals.userAccount
        if (vals.userIp !== undefined) searchParamsTmp.userIp = vals.userIp
        if (vals.login !== undefined) {
          searchParamsTmp.loginBegin = vals.login ? vals.login[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.loginEnd = vals.login ? vals.login[1].format('YYYY-MM-DD') : ''
        }
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getLogLogList()
      }
    })
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      searchParamsTmp.sort = sorter.columnKey
      searchParamsTmp.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      searchParamsTmp.sort = ''
      searchParamsTmp.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp.limit = pageSize
    searchParamsTmp.page = currentPage
    getLogLogList()
  }

  return (
    <>
      <div className="search-doc-wrap search-office-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="用户名">
              {getFieldDecorator('userName', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="登录账号">
              {getFieldDecorator('userAccount')(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="登录IP">
              {getFieldDecorator('userIp', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="登录时间">
              {getFieldDecorator('login', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="用户名" dataIndex="userName" />
          <Column title="登录账号" dataIndex="userAccount" />
          <Column title="登录IP" dataIndex="userIp" />
          <Column title="登录时间" dataIndex="userLoginTime" sorter />
          <Column title="退出时间" dataIndex="userLogoutTime" sorter />
        </Table>
      </div>
    </>
  )
}

export default Form.create()(LoginLog)
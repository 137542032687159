/**
 * 模块名称: 后台首页 登录副账号时的提示
 * @author lids@372163.com
 */
import React from 'react'

class Tips extends React.PureComponent{
  render(){
    return <section>
      <p>各位同事好，</p>

      <p style={{textIndent: '28px'}}>当您看到这段文字时，说明您在易企信中登录了副账号。</p>

      <p>主账号、副账号的定义：</p>

      <p style={{textIndent: '28px'}}>Ø 主账号：在与您签订劳动合同的公司主体下为您创建的账号，主账号只有一个。</p>

      <p style={{textIndent: '28px'}}>Ø 副账号：在非您签订劳动合同的公司主体下为您创建的账号，副账号可以有多个。</p>

      <p>主账号、副账号的功能：</p>

      <p style={{textIndent: '28px'}}>Ø 主账号：在该账号中您有易企信和老OA所有的功能和权限。</p>

      <p style={{textIndent: '28px'}}>Ø 副账号：在该账号下您没有易企信的功能和权限，只有老OA现有的功能和权限，您可以在老OA通过右上角的“切换账号”按钮切换您的主账号与其他副账号；</p>

      <p>在2014年研发完成并投入使用的老OA已经运行了6年，该系统为我公司的流程规范与信息管理立下了汗马功劳。但随着我公司业务的蓬勃增长和组织结构的不断优化，老OA在系统架构和业务逻辑上已经无法满足我公司当前需求。</p>

      <p>在公司内控和财务不断规范的趋势下，在内控部和财务部的支持下，我们构建了全新的系统架构并实现了扁平化的业务逻辑。目前我们正处于新老系统切换的过程中，由于老OA中还会涉及历史订单的退单、还负利等情形，所以老OA将在较长的一段时间中，与新系统并存。</p>

    </section>
  }
}
export default Tips
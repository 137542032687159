/**
 * 模块名称: 短信日志
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Alert,
  Popover
} from 'antd'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  sort: '', //addTime
  sortMethod: '' //desc
}
//模板管理页码参数
let statusData = ['发送失败', '已发送', '待发送']

const ShortMessage = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match, location } = props

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)

  const intervalRef = useRef()

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogSmsList()
    return () => clearTimeout(intervalRef.current)
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取短信日志列表
  const getLogSmsList = () => {
    let id = setTimeout(() => {
      api.getLogSmsList(searchParamsTmp).then(res => {
        //console.log(res)
        setList(res.list)
        setCount(res.count)
        setLoading(false)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogSmsList()
  }

  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    //currentPage = 1
    validateFields((err, vals) => {
      if (vals !== undefined) {
        if (vals.mobile !== undefined) searchParamsTmp.mobile = vals.mobile
        if (vals.templateId !== undefined) searchParamsTmp.templateId = vals.templateId
        if (vals.status !== undefined) searchParamsTmp.status = vals.status
        if (vals.sendTime !== undefined) {
          searchParamsTmp.sendTimeBegin = vals.sendTime ? vals.sendTime[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.sendTimeEnd = vals.sendTime ? vals.sendTime[1].format('YYYY-MM-DD') : ''
        }
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getLogSmsList()
      }
    })
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      searchParamsTmp.sort = sorter.columnKey
      searchParamsTmp.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      searchParamsTmp.sort = ''
      searchParamsTmp.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp.limit = pageSize
    searchParamsTmp.page = currentPage
    getLogSmsList()
  }

  return (
    <>
      <div className="search-doc-wrap search-office-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="手机号码">
              {getFieldDecorator('mobile', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="模板ID">
              {getFieldDecorator('templateId')(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="状态">
              {getFieldDecorator('status', {
              })(<Select placeholder="请选择">
                {
                  statusData.map((v, i) => {
                    return <Option value={v} key={i}>{v}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="发送时间">
              {getFieldDecorator('sendTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="手机号码" key="mobile" render={(text, record) => (
            <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text.mobile}</Link>
          )} />
          <Column title="签名" dataIndex="sign" />
          <Column title="模板ID" dataIndex="alitplId" />
          <Column title="失败原因" key="failedReason" render={(text, record) => (
            <Popover content={text.failedReason} title="失败原因">
              <div>{text.failedReason}</div>
            </Popover>
          )} />
          <Column title="状态" dataIndex="status" />
          <Column title="发送时间" dataIndex="updateTime" sorter />
        </Table>
      </div>
    </>
  )
}

export default Form.create()(ShortMessage)
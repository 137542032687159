import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ShortMessage from './ShortMessage'
import Details from './Details'

const ShortMessageIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route component={ShortMessage} />
    </Switch>
  )
}

export default ShortMessageIndex
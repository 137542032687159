/**
 * 模块名称: 金蝶管理
 * @author xuxiaobo@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Log from './Log'
import KingMap from './Map'
import Details from './Details'
import ExportExcel from './ExportExcel'

const Kingdee = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        <Route path={match.path + '/export'} component={ExportExcel} />
        <Route path={match.path + '/details'} component={Details} />
        <Route path={match.path + '/map'} component={KingMap} />
        <Route path={match.path + '/log'} component={Log} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Kingdee
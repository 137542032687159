/**
 * 模块名称: 帮助中心列表 -> 帮助中心
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
// import urls from '@/api/urls'
import {
  Table,
  Drawer,
  Form,
  Alert,
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Spin
} from 'antd'
import { parseSearch } from '@/utils'
import { Link } from 'react-router-dom'
import AddSupport from './module/AddSupport'

const { Column } = Table
const { RangePicker } = DatePicker
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let Key = ''

let filter = {
  title: '',
  class: '',
  userName: '',
  searchCreationTimeBegin: '',
  searchCreationTimeEnd: '',
  sort: '',
  sortMethod: '' //desc
}

const HelpCenter = (props) => {
  const { match, location, history } = props.props
  const { getFieldDecorator, validateFields, resetFields } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [questionClass, setQuestionClass] = useState([])
  const [formLoading, setformLoading] = useState(false)//eslint-disable-line
  const search = parseSearch(location.search)
  Key = search.activeKey ? +search.activeKey : '1'

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10

    filter = {
      title: '',
      class: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: '',
      sort: '',
      sortMethod: '' //desc
    }
    getList()
    onStionClass()
  }, [Key])

  const getList = () => {
    setLoading(true)
    api.getSupportquestionList({ ...filter, limit: pageSize, page: currentPage })
      .then(data => {
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      filter.sort = sorter.columnKey
      filter.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      filter.sort = ''
      filter.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize + '&activeKey=' + 1)
    getList()
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=1')
        filter = {
          ...filter,
          ...vals,
          searchCreationTimeBegin: vals.searchCreationTime ? vals.searchCreationTime[0].format('YYYY-MM-DD') : '',
          searchCreationTimeEnd: vals.searchCreationTime ? vals.searchCreationTime[1].format('YYYY-MM-DD') : ''
        }
        getList()
      }
    })
  }

  // 重置
  const onReset = () => {
    resetFields()
    filter = {
      title: '',
      class: '',
      userName: '',
      searchCreationTimeBegin: '',
      searchCreationTimeEnd: ''
    }
    currentPage = 1
    history.replace(match.path + '?page=' + 1 + '&limit=' + pageSize + '&activeKey=1')
    getList()
  }

  // 类型
  const onStionClass = () => {
    api.getSupportquestionClass({limit: global.paramsLimit}).then(data => {
      setQuestionClass(data.list)
    })
  }

  //打开分类管理列表
  const onManage = () => {
    setVisible(true)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <div className="helpCenter-con">
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo helpCenter-form">
        <div>
          <div>
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="标题" labelCol={{ span:4 }}>
                  {getFieldDecorator('title')(<Input placeholder="请输入标题" />)}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="类型" labelCol={{ span: 4 }}>
                  {getFieldDecorator('class')(
                    <Select placeholder="全部">
                      {questionClass&&questionClass.map(item => <Option key={item.id} value={item.id}>{item.title}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem label="创建人" labelCol={{ span: 6 }}>
                  {getFieldDecorator('userName')(<Input placeholder="请输入创建人" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="创建日期" labelCol={{ span: 6 }}>
                  {getFieldDecorator('searchCreationTime')(<RangePicker allowClear={false}  />)}
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
        <div className="recruit-search-btns">
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="add-wrap">
        <Button type="primary" icon="message" title="我要提问" onClick={onManage}>我要提问</Button>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        //  scroll={{ x: 2200 }}
        onChange={onChangeTable}
   >
    <Column title="标题"
      dataIndex="title"
      ellipsis={true}
      width="30%"
      render={(text, record) =><Link to={`/helplist/detail?id=${record.id}`}>{text}</Link>}
    />
     <Column title="类型" dataIndex="supportQuestionClassName" />
     <Column title="创建人" dataIndex="userName" />
     <Column title="浏览次数" dataIndex="readNumber" sorter />
     <Column title="有帮助" dataIndex="useFul" sorter />
     <Column title="没帮助" dataIndex="useLess" sorter />
     <Column title="创建时间" dataIndex="addTime" sorter />
   </Table>
      <Drawer
        title="我要提问"
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={formLoading}>
        <AddSupport
          setVisible={setVisible}
          getList={getList}
          />
        </Spin>
      </Drawer>
    </div>
  );
}

export default Form.create()(HelpCenter)

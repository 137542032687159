/**
 * 模块名称: 日志管理
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './assets/style.scss'
import LoginLog from './LoginLog'
import OperationLog from './OperationLog'
import MailLogs from './MailLogs'
import ShortMessage from './ShortMessage'

const Management = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/loginLog'} component={LoginLog} />
      <Route path={match.path + '/operationLog'} component={OperationLog} />
      <Route path={match.path + '/mailLog'} component={MailLogs} />
      <Route path={match.path + '/shortMessageLog'} component={ShortMessage} />
    </Switch>
  )
}

export default Management
/**
 * 模块名称: 厂商id收入明细列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  DatePicker
} from 'antd'
import { parseSearch } from 'utils'
import CorpFilter from 'components/CorpFilter'
import Auth from 'components/AuthMiddleware'
import moment from 'moment'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1

let filter = {
  branch: '',
  relationId: '',
  relationType: '',
}

let params = {}

const Details = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [productList, setProductList] = useState([])
  const [authList, setAuthList] = useState([])//按钮权限
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    getProjectList()
    getPageAuth()
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const getList = () => {
    setLoading(true)
    api.kingdeeGetDetailList({ ...filter, limit: pageSize, page: currentPage })
    .then(data => {
      setLoading(false)
      setList(data.list)
      setCount(data.count)
    })
    .catch(() => setLoading(false))
  }

  const syncdata = () => {
    setLoading(true)
    api.kingdeeSyncToKingdee({ ...filter, limit: pageSize, page: currentPage })
    .then(data => {
      message.success('同步成功')
      setLoading(false)
    })
    .catch(() => setLoading(false))
  }

  const getProjectList = () => {
    api.getsysProjectChilds({limit: global.paramsLimit, page: 1, pid: 0 })
    .then(data => {
      setProjectList(data)
    })
    .catch(() => setLoading(false))
  }

  const getProductList = () => {
    let vals = getFieldsValue(['pco_project'])
    // console.log(vals.pco_project)
    api.getsysProjectChilds({limit: global.paramsLimit, page: 1, pid: vals.pco_project })
    .then(data => {
      setProductList(data)
    })
    .catch(() => setLoading(false))
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        filter = { ...filter, ...vals }
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }

  //同步
  const onSync = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        filter = { ...filter, ...vals }
        syncdata()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    setFieldsValue({
      id: data.id ? data.id : undefined,
      type: data.type ? data.type : undefined,
      params: data.params ? data.params : undefined,
      response: data.response
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }
          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onChangeCorp = (data) => {
    filter.branch = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.adl_xiaotime_start = ''
      filter.adl_xiaotime_end = ''
    } else {
      filter.adl_xiaotime_start = moment(data[0]).format('YYYY-MM-DD')
      filter.adl_xiaotime_end = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
    <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="项目" labelCol={{span: 8}}>
                  {getFieldDecorator('pco_project')(
                    <Select placeholder="请选择" onSelect={getProductList}>
                      {projectList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="产品" labelCol={{span: 8}}>
                  {getFieldDecorator('pco_product')(
                    <Select mode="multiple" placeholder="请选择">
                      {productList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="消耗日期">
                  {getFieldDecorator('adl_xiaotime', { initialValue: ''})(<RangePicker onChange={onChangePicker} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
              >
                {filterContent.map((item, index) =>
                  <Option value={index} key={index}>
                    <div className="closeStyBtn">
                      {item.name}
                      {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                        e.stopPropagation()
                        onSearchDelete(item.id)
                      }} /></span> : null}
                    </div>
                  </Option>
                )}
              </Select>
            </div>
            <Button className="filter-savebtn" style={{marginLeft: 8, marginRight: 8}} onClick={onSaveFilterTerm}>保存筛选条件</Button>
          </div>

          <Button style={{ marginLeft: 8, marginRight: 15 }} type="primary" htmlType="submit">查询</Button>
          <Auth auths={authList} code="operate">
            <Button icon="sync" onClick={(e) => onSync(e)}> 同步</Button>
          </Auth>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert className="count-alert" message={
        <>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      }/>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 2400}}
        onChange={onChangeTable}
      >
        <Column title="记录ID" dataIndex="adl_id" />
        <Column title="OAID" dataIndex="adl_oaid" />
        <Column title="OA客户/渠道名称" dataIndex="adl_oaname" />
        <Column title="厂商客户名称" dataIndex="adl_cname" />
        <Column title="客户类型" dataIndex="adl_customertype" />
        <Column title="账户" dataIndex="adl_accountid" />
        <Column title="项目" dataIndex="adl_oname" />
        <Column title="产品" dataIndex="adl_productname" />
        <Column title="业务类型" dataIndex="adl_businesstype_text" />
        <Column title="收款类型" dataIndex="adl_paytype" />
        <Column title="业务编号" dataIndex="adl_businessno" />
        <Column title="业务ID" dataIndex="adl_businessid" />
        <Column title="厂商ID" dataIndex="adl_cid" />
        <Column title="市场价" dataIndex="adr_marketprice" />
        <Column title="实收" dataIndex="adr_paidin" />
        <Column title="预计收款" dataIndex="adr_colpredictprice" />
        <Column title="单价" dataIndex="adr_unitprice" />
        <Column title="消耗" dataIndex="adl_consumption" />
        <Column title="收入" dataIndex="adl_income" />
        <Column title="厂商余额" dataIndex="adl_balance" />
        <Column title="可用消耗" dataIndex="adl_available" />
        <Column title="可用余额" dataIndex="adl_money" />
        <Column title="转出/转入" dataIndex="adl_ordersource" />
        <Column title="员工编号" dataIndex="adl_stfno" />
        <Column title="部门" dataIndex="adl_department" />
        <Column title="导入时间" dataIndex="adl_addtime" />
        <Column title="消耗日期" dataIndex="adl_xiaotime" />
      </Table>
    </>
  )
}

export default Form.create()(Details)
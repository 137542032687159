/**
 * 模块名称: 银行
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Table,
  Form,
  Input,
  Button,
  message,
  Alert,
  Row, 
  Col,
  Popconfirm,
  Drawer,
  Spin,
} from 'antd'
import { parseSearch } from 'utils'
import BtnGroup from 'components/BtnGroup'
import Auth from 'components/AuthMiddleware'

const { Column } = Table
const FormItem = Form.Item

let pageSize = 10
let currentPage = 1

let searchParamsTmp = {
  limit: pageSize, 
  page: currentPage,
  type: 'bank',
}

let editId = 0 //编辑使用

const BankList = (props) => {

  const { getFieldDecorator, validateFields } = props.form
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const search = parseSearch(props.location.search)
  const [detailData, setDetailData] = useState([])
  const [drawerTitle, setDrawerTitle] = useState('添加银行')
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)

  const authList = props.authList
  
  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    getList()
  },[])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  } 

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    getList()
  }

  //获取列表
  const getList = () => {
    setLoading(true)
    api.kingdeeGetMapList(searchParamsTmp).then(res => {
      setCount(res.count)
      setList(res.list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
 
  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        searchParamsTmp.page = currentPage = 1 
        searchParamsTmp.kingdeeCode = vals.kingdeeCode
        getList() 
      }
    })
  }

  const onEdit = (data) => {
    editId = data.id
    if(data.id > 0){
      setDrawerTitle('编辑银行')
      setDetailData(data)
    }
    else{
      setDrawerTitle('添加银行')
    }
    setVisibleEdit(true)
  }

  const onDelete = (data) => {
    api.kingdeeDeleteMap({id: data.id, type: searchParamsTmp.type}, true).then(res => {
      message.success('删除成功')
      getList()
    })
  }

  // 保存
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        setSaveLoading(true)
        api.kingdeeSaveMap({
          id: editId,
          type: searchParamsTmp.type,
          bankAccountCode: vals.edit_bankAccountCode,
          bankAccountName: vals.edit_bankAccountName,
          bankCode: vals.edit_bankCode,
          bankName: vals.edit_bankName,
          payeeBank: vals.edit_payeeBank
        }).then(() => {
            setSaveLoading(false)
            setVisibleEdit(false)
            message.success('操作成功')
            getList()
          })
          .catch(() => setSaveLoading(false))
        }
    })
  }

  const onDownload = (type) => {
    window.open('/api/fortune/Kingdee/DumpMapList?type='+type+'&limit='+global.paramsLimit)
  }

  return (
    <> 
      <div className="search-askforleave-wrap"> 
        <Form onSubmit={searchSubmit} {...formItemLayout} > 
          <div className="search-item-wrap">
                <div className="search-item">
                    <Row gutter={10}  >
                        <Col span={10}>
                            <FormItem label="金蝶编码" style={{textAlign:"center"}}> {getFieldDecorator('kingdeeCode')(<Input   placeholder="请输入金蝶编码查询"  />)} </FormItem>  
                        </Col>
                        <Col span={14} className="search-buttons">
                            <Button type="primary" style={{marginTop:5}} htmlType="submit" icon="search"> 查询</Button>
                            &nbsp;&nbsp;&nbsp;<Auth auths={authList} code="operate"><Button type="primary" style={{marginTop:5}} htmlType="button" icon="plus" onClick={() => onEdit({id:0})}> 添加</Button></Auth>
                            &nbsp;&nbsp;&nbsp;<Auth auths={authList} code="operate"><Button type="primary" style={{marginTop:5}} htmlType="button" icon="download" onClick={() => onDownload('bank')}> 导出</Button></Auth>
                        </Col>
                    </Row>
                </div>
          </div>  
        </Form>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable} 
      > 
        <Column title="序号" dataIndex="id" /> 
        <Column title="(单据体)银行账号#编码" dataIndex="bankAccountCode" />
        <Column title="(单据体)银行账号#名称" dataIndex="bankAccountName" />
        <Column title="(单据体)银行#编码" dataIndex="bankCode" /> 
        <Column title="(单据体)银行#名称" dataIndex="bankName" /> 
        <Column title="OA到款银行" dataIndex="payeeBank" /> 
        <Column title="操作"
          key="set" 
          fixed="right"
          width={150}
          render = {(record) => {
            return (
              <Auth auths={authList} code="operate">
                <span className="hrefspan" onClick={() => onEdit(record)}>修改</span> 
              &nbsp;&nbsp;&nbsp;
                <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record)}>
                  <span className="hrefspan">删除</span>
                </Popconfirm>
              </Auth>
            )
          }}
        />
      </Table>

      <Drawer
        title={drawerTitle}
        width={1000}
        onClose={() => setVisibleEdit(false)}
        visible={visibleEdit}
        destroyOnClose={true}
      >
        <Spin spinning={false}>
          {
            visibleEdit ? <Form onSubmit={formSubmits} {...formItemLayout}>
              <FormItem label="(单据体)银行账号#编码" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_bankAccountCode', {
              initialValue: detailData.bankAccountCode, rules: [{ required: true, message: '请输入(单据体)银行账号#编码' }]
              })(<Input placeholder="请输入" />)}
              </FormItem>

              <FormItem label="(单据体)银行账号#名称" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_bankAccountName', {
              initialValue: detailData.bankAccountName, rules: [{ required: true, message: '请输入(单据体)银行账号#名称'}]
              })(<Input placeholder="请输入"  />)}
              </FormItem>

              <FormItem label="(单据体)银行#编码" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_bankCode', {
              initialValue: detailData.bankCode, rules: [{ required: true, message: '请输入(单据体)银行#编码'}]
              })(<Input placeholder="请输入"  />)}
              </FormItem>

              <FormItem label="(单据体)银行#名称" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_bankName', {
              initialValue: detailData.bankName, rules: [{ required: true, message: '请输入(单据体)银行#名称'}]
              })(<Input placeholder="请输入"  />)}
              </FormItem>

              <FormItem label="OA到款银行" className="staffadd-FormItem staffadd-required">
              {getFieldDecorator('edit_payeeBank', {
              initialValue: detailData.payeeBank, rules: [{ required: true, message: '请输入OA到款银行'}]
              })(<Input placeholder="请输入"  />)}
              </FormItem>

              <BtnGroup onCancel={() => setVisibleEdit(false)} loading={saveLoading} />
            </Form> : <div></div>
          }
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(BankList)
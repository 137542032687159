/**
 * 模块名称:映射关系
 * @author xuxiaobo@372163.com
 */

import React, { useEffect, useState } from 'react'
import {
  Tabs
} from 'antd'
import { parseSearch } from 'utils'
import BankList from './module/BankList'
import ZhangbuList from './module/ZhangbuList'
import ProjectList from './module/ProjectList'
import api from 'api'

const KingMap = (props) => {
  const { location } = props
  const search = parseSearch(props.location.search)
  const [authList, setAuthList] = useState([])//按钮权限
  const [authRequred, setAuthRequred] = useState(false)

  useEffect(() => {
    getPageAuth()
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
      setAuthRequred(true)
    })
  }

  const { TabPane } = Tabs

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="银行" key="1">
          {authRequred && <BankList id={search.id} location={location} authList={authList} />}
        </TabPane>
        <TabPane tab="账簿" key="2">
          {authRequred && <ZhangbuList id={search.id} location={location} authList={authList} />}
        </TabPane>
        <TabPane tab="金蝶项目&产品" key="3">
          {authRequred && <ProjectList id={search.id} location={location} authList={authList} />}
        </TabPane>
      </Tabs>
    </>
  )
}

export default KingMap
/**
 * 模块名称: 下载列表
 * @author zhuyan@372163.com
 */
import React, { useState, useEffect } from 'react'
import {
  Table,
  Alert,
  Form,
  Button,
  Input,
  DatePicker,
  Icon,
  Modal,
  message,
  Tooltip
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import Auth from '@/components/AuthMiddleware'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker;
let pageSize = 10
let currentPage = 1
let intervalValue = null

let exporfilter = {
  module: '',
  fileName: '',
  applyTimeBegin: '',
  applyTimeEnd: '',
  sort: '', //addTime
  sortMethod: '' //desc
}

const Paper = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  // const [authList, setAuthList] = useState([])
  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    exporfilter = {
      module: '',
      fileName: '',
      applyTimeBegin: '',
      applyTimeEnd: '',
      sort: '', //addTime
      sortMethod: '' //desc
    }
    resetFields()
    getPaperList()
    // api.getPageAuth().then(list => setAuthList(list))
  }, [])

  useEffect(() => {
    return ()=>{
      if (intervalValue != null) {
         clearInterval(intervalValue)
        intervalValue = null
      }
    }
  },[])

  const getPaperList = () => {
    setLoading(true)
    api.exportList({
      limit: pageSize,
      page: currentPage,
      module: exporfilter.module,
      fileName: exporfilter.fileName,
      applyTimeBegin: exporfilter.applyTimeBegin,
      applyTimeEnd: exporfilter.applyTimeEnd,
      sort: exporfilter.sort,
      sortMethod: exporfilter.sortMethod
    }).then(data => {
      if (search.msgId) {
        api.setMessageReaded({ ids: [search.msgId] })
      }
      setList(data.list)
      setCount(data.count)
      setLoading(false)
      setIntervalPaperList(data.list)
    }).catch(() => setLoading(false))
  }

  const setIntervalPaperList = (list) => {
    let flag = false
    for (let i = 0; i < list.length; i++) {
      if (list[i].status === 0 || list[i].status === 1) {
        flag = true
        break
      }
    }

    if (flag) {
      if (intervalValue == null) {
        intervalValue = setInterval(() => {
          getPaperList()
        }, 20000)
      }
    } else {
      if (intervalValue != null) {
        clearInterval(intervalValue)
        intervalValue = null
      }
    } 
  }

  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      exporfilter.sort = sorter.columnKey
      exporfilter.sortMethod = sorter.order == 'ascend' ? 'asc' : 'desc'
    } else {
      exporfilter.sort = ''
      exporfilter.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getPaperList()
  }

  // 查询
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        history.replace(match.path)
        currentPage = 1
        exporfilter.module = vals.module ? vals.module : ''
        exporfilter.fileName = vals.filename ? vals.filename : ''
        exporfilter.applyTimeBegin = vals.inpicker ? vals.inpicker[0].format('YYYY-MM-DD') : ''
        exporfilter.applyTimeEnd = vals.inpicker ? vals.inpicker[1].format('YYYY-MM-DD') : ''
        getPaperList()
      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  // 重置
  const onReset = (e) => {
    e.preventDefault()
    exporfilter.module = ''
    exporfilter.fileName = ''
    exporfilter.applyTimeBegin = ''
    exporfilter.applyTimeEnd = ''
    // exporfilter.sort = ''
    // exporfilter.sortMethod = ''
    resetFields()
    history.replace(match.path)
    currentPage = 1
    getPaperList()
  }

  // 下载
  const onLoadFile = (data) => {

    /*- if(data.filePath.indexOf("://"))
     { 
       window.location= "http://devnewoa.372163.com/api/File/Downloads?url=" + data.filePath + "&name=" + data.fileName;
     }else{
     api.setFileDownloads({
       url: data.filePath,
       name: data.fileName
     })
     .then(data => {    
     }).catch(() => {
       message.error('下载失败', 3)
     })
    }*/
    api.getHost({})
      .then(url => {
        data.filePath !== '' ?
          window.location = url.url + "/api/File/Downloads?url=" + data.filePath + "&name=" + data.fileName :
          message.error('下载失败', 3);
      })
  }

  // const onLoadFile = (filePath) => {
  //   let url = ''
  //   let origin = window.location.origin

  //   if (filePath.indexOf('://') !== -1) {
  //     url = filePath
  //   }
  //   else if (origin.indexOf('localhost' !== -1)) {
  //     url = 'http://devnewoa.372163.com' + filePath
  //   }
  //   else {
  //     url = origin + filePath
  //   }
  //   window.open(url, "_blank");
  // }

  // 重新下载
  const onRedo = (id) => {
    api.setFileredo({
      id: id
    })
      .then(data => {
        setLoading(false)
        history.replace(match.path)
        currentPage = 1
        getPaperList(currentPage)
      }).catch(() => {
        setLoading(false)
        message.error('重新下载失败', 3)
      })
  }

  // 提示原因
  const onfailedReason = (failedReason) => {
    // setShowVisble(true)
    Modal.confirm({
      title: '失败原因',
      content: failedReason,
      okText: "确认",
      cancelText: '关闭',
    })
  }

  return (
    <>
      <div className="search-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <FormItem label="文件名称">
            {getFieldDecorator('filename', {
            })(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="导出模块">
            {getFieldDecorator('module')(<Input placeholder="请输入" />)}
          </FormItem>
          <FormItem label="申请时间">
            {getFieldDecorator('inpicker')(<RangePicker allowClear={false} />)}
          </FormItem>
          <FormItem className="btns" label=" ">
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="white-line"></div>
      <div>
        <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
        <Table
          size="middle"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="文件名称" dataIndex="fileName" width={360} />
          <Column title="导出模块" dataIndex="module" />
          <Column title="申请时间" dataIndex="addTime" sorter />
          <Column title="导出进度" dataIndex="status" render={(text, record) => {
            if (text === 0) {
              return '待导出'
            } else if (text === 1) {
              return '导出中'
            } else if (text === 2) {
              return '导出成功'
            } else {
              return '导出失败'
            }
          }} />
          <Column title="操作" key="set" fixed="right" width={200} render={(text, record) => {
            if (record.status === 2) {
              return (
                <Auth auths={text.operateAuth} code="operate">
                  <Tooltip title="下载" placement="bottom">
                    <Icon type="cloud-download" style={{ color: '#1890ff' }} onClick={() => onLoadFile(record)} />
                  </Tooltip>
                </Auth>
              )
            } else if (record.status === 3) {
              return (
                <div className="failButton">
                  <Auth auths={text.operateAuth} code="operate">
                    <Button type="link" onClick={() => onRedo(text.id, 'up')}>重新下载</Button>
                  </Auth>
                  <Auth auths={text.operateAuth} code="operate">
                    <Button type="link" onClick={() => onfailedReason(record.failedReason)}>失败原因</Button>
                  </Auth>
                </div>
              )
            } else {
              return ''
            }
          }} />
        </Table>
      </div>
    </>
  )
}

export default Form.create()(Paper)

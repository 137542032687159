/**
 * 模块名称: 短信日志=>详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Divider,
  message
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

let tplIds

const Details = (props) => {
  const { history } = props

  const [detailList, setDetailList] = useState([])
  // const [smsTplData, setSmsTplData] = useState([])
  // const [tplId, setTplId] = useState('')
  const [list, setList] = useState({})
  const [cont, setCont] = useState('')
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      api.getLogSmsDetail({ id: search.id }, true).then(data => {
        tplIds = data.tplId
        if (search.msgId) {
          api.setMessageReaded({ ids: [search.msgId] })
        }
        setDetailList(data)
        // console.log('data', data)
        let code = Object.values(JSON.parse(data.content))[0]
        api.getThirdPartySmsTpl({}, true).then(data => {
          //setSmsTplData(data.options)
          if(data.list[tplIds]){
            // console.log('data.list[tplIds]', data.list[tplIds])
            setList(data.list[tplIds])
            let str = data.list[tplIds].content
            let content = str.replace(/\${code}/g, code)
            setCont(content)
          }
        })
      })
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }

  }, [])

  return (
    <>
      <div className="mail-detail">
        <h1>{list.option ? list.option : null}</h1>
        <div className="mail-title">
          <div className="mail-name">手机号码：{detailList.mobile}</div>
          <div className="mail-name">发送时间：{detailList.updateTime}</div>
        </div>
        <Divider />
        <div className="mail-content1">{cont}</div>
      </div>
    </>
  )
}

export default Details
/**
 * 模块名称: 首页列表
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Table, Modal } from 'antd'
import { Link } from 'react-router-dom'
import api from '@/api'
import CryptoJS from 'crypto-js'
const { Column } = Table

// 待办事项
const urlObj = {
  44: '/performance/adjustment/detail?id=',
  10: '/hr/promotion/detail?id=',
  18: '/office/procedure/officeworkdetail?id=',
  19: '/hr/jobTransfer/detail?id=',
  9: '/hr/recruit/detail?id=',
  12: '/protocol/invoice/detail?id=',
  13: '/hr/dimission/detail?id=',
  8: '/attendance/appeal/details?id=',
  15: '/attendance/askforleave/addaskdetail?id=',
  20: '/setSystem/helpCenterMgt/replydetail?id=',
  31: '/csr/vendorIdAudit/detail?id=',
  38: '/orderDirect/directDetail?id=',
  39: '/orderAgent/agentDetail?id=',
  28: '/csr/addAudit/detail?id=',
  29: '/csr/nameChangeAudit/detail?id=',
  33: '/protocol/replenish/details?id=',
  34: '/protocol/contract/details?id=',
  35: '/protocol/quittance/details?id=',
  30: '/account/creditapply/details?id=',
  23: '/fund/refundManage/details?id=',
  17: '/fund/moneyTransmit/details?id=', // 转款
  22: '/fund/moneyClaim/detail?id=', // 认款
  24: '/agent/agentNew/agentNewDetail?id=', // 代理商新增
  244: '/agent/nameManage/details?id=', // 代理商变更
  26: '/agent/manufacturer/details?id=',
  32: '/csr/turnAudit/detail?id=',
  25: '/agent/integralManage/details?id=',
  36: '/protocol/invoice/detail?id=', // 发票清理
  37: '/protocol/invoice/detail?id=', // 发票冲红
  27: '/agent/agentTransfer/detail?id=',
  40: '/orderDirect/refundDetail?id=', //直销退单
  41: '/orderAgent/refundDetail?id=', //代理商退单
  42: '/orderDirect/paybackDetail?id=', //直销负利还款
  43: '/orderAgent/paybackDetail?id=', //直销负利还款
  45: '/protocol/replenish?id=', //补充协议管理执行
  46: '/supplie/topUp/detail?id=', //系统充值
  47: '/office/costManagement/details?id=', //费用审批
  48: '/asset/purchase/details?id=', // 采购
  49: '/attendance/askforleave/addaskdetails?id=', //育儿假资格申请
  51: '/corpAccount/InternalAccount/details?id=', //内部往来款
  50: '/supplie/supplierContract/details?id=', //合同内容确认
  52: '/supplie/supplierContractSeal/details?id=' //合同盖章申请
}
 
// 系统消息 (补充资料)
const newsObjNew = {
  12: '/protocol/invoice/apply?id=', // 发票
}

// 系统消息 (跳转详情)
const newsObj = {
  0: '/office/procedure/officeworkdetail?id=',
  10: '/hr/promotion/detail?id=',
  20: '/hr/jobTransfer/detail?id=',
  26: '/hr/employee/details?id=',
  25: '/helplist?id=',
  27: '/importDown/tolead?id=',
  9: '/hr/recruit/detail?id=',
  13: '/hr/dimission/detail?id=',
  8: '/attendance/appeal/details?id=',
  15: '/attendance/askforleave/addaskdetail?id=',
  5: '/attendance/individual?id=',
  30: '/helplist/details?id=',
  19: '',
  28: '',
  29: '',
  23: '',
  18: '',
  21: '',
  22: '',
  12: '/protocol/invoice/detail?id=',
  37: '/csr/addAudit/detail?id=',
  38: '/csr/nameChangeAudit/detail?id=',
  40: '/csr/vendorIdAudit/detail?id=',
  43: '/protocol/replenish/details?id=',
  44: '/protocol/contract/details?id=',
  45: '/protocol/quittance/details?id=',
  48: '/orderDirect/directDetail?id=',
  49: '/orderAgent/agentDetail?id=',
  39: '/account/creditapply/details?id=',
  32: '/fund/refundManage/details?id=',
  17: '/fund/moneyTransmit/details?id=', // 转款
  31: '/fund/moneyClaim/detail?id=', // 认款
  33: '/agent/agentNew/agentNewDetail?id=', // 代理商新增
  333: '/agent/nameManage/details?id=', // 代理商变更
  35: '/agent/manufacturer/details?id=',
  42: '/csr/turnAudit/detail?id=',
  34: '/agent/integralManage/details?id=',
  46: '/protocol/invoice/detail?id=', // 发票清理
  47: '/protocol/invoice/detail?id=', // 发票冲红
  36: '/agent/agentTransfer/detail?id=',
  50: '/orderDirect/refundDetail?id=', //直销退单
  51: '/orderAgent/refundDetail?id=', //代理商退单
  52: '/orderDirect/paybackDetail?id=', //直销负利还款
  53: '/orderAgent/paybackDetail?id=', //直销负利还款
  57: '/supplie/topUp/detail?id=', //系统充值
  58: '/office/costManagement/details?id=', //费用审批
  59: '/asset/purchase/details?id=', // 采购
  60: '/attendance/askforleave/addaskdetails?id=', //育儿假资格申请
  62: '/corpAccount/InternalAccount/details?id=', //公司内部往来款
  61: '/supplie/supplierContract/details?id=', //合同内容确认
  63: '/supplie/supplierContractSeal/details?id=' //合同盖章申请
}

const Module = ({ data, loading, onChange, getIndexData }) => {

  const onMouseDown = e => {
    e.currentTarget.classList.add('catching')
    // console.log('monse down')
  }

  const onMouseUp = e => {
    e.currentTarget.classList.remove('catching')
    // console.log('onMouseUp')
  }

  const onDragStart = (e) => {
    // console.log('onDragStart target ==>', e.target)
    e.dataTransfer.setData('id', e.target.getAttribute('data-id'))
  }

  const onDragOver = (e) => {
    // console.log('onDragOver data id ==>', e.currentTarget.getAttribute('data-id'))
    e.preventDefault()
    const target = e.currentTarget
    clearCatching()
    if (target.className.indexOf('catching') === -1) {
      target.classList.add('catching')
    }
  }

  const clearCatching = () => {
    const modulesWrap = document.querySelector('.modules-wrap')
    const modules = modulesWrap.querySelectorAll('.module-item')
    for (let i = 0; i < modules.length; i++) {
      modules[i].classList.remove('catching')
    }
  }

  const onDrop = (e) => {
    e.preventDefault()
    // e.persist()
    // console.log('onDrop currentTarget ==>', e.currentTarget.getAttribute('data-id'))
    // console.log('onDrop getData ==>', e.dataTransfer.getData('id'))
    clearCatching()
    const index1 = e.dataTransfer.getData('id')
    const index2 = e.currentTarget.getAttribute('data-id')

    if (index1 === index2) {
      return
    }

    // 1拖拽的元素 2被交换的元素
    onChange(+index1, +index2)
  }

  const renderTitle = () => {
    switch (data.moduleId) {
      case 1: case 2:
        return <h4 className="title">{data.moduleName}{!!data.unRead && <span className="un-read">{data.unRead > 99 ? '99+' : data.unRead}</span>}</h4>
      case 3: case 4: case 6:
        return <h4 className="title">{data.moduleName}{!!data.unRead && <span className="un-read-dot"></span>}</h4>
      default:
        return <h4 className="title">{data.moduleName}</h4>
    }
  }

  const onShowModal = (id, title) => {
    Modal.info({
      title: '消息内容',
      content: (
        <div>
          <p>{title}</p>
        </div>
      ),
      onOk() {
        api.setMessageReaded({ ids: [id] }).then(data => getIndexData())
      }
    })
  }

  const onSchedule = (title) => {
    Modal.info({
      title: '消息内容',
      content: (
        <div>
          <p>{title}</p>
        </div>
      ),
      onOk() {
      }
    })
  }

  return (
    <div
      className="module-item"
      draggable="true"
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      data-id={data.sort}
      id={`module_${data.sort}`}
    >
      <div className="module-title">
        {renderTitle()}
        {data.moduleId !== 5 && <Link to={(() => {
          switch (data.moduleId) {
            case 1:
              return '/message'
            case 2:
              return '/schedule'
            case 3:
              return '/notice'
            case 4:
              return '/regulation'
            case 6:
              return '/document'
          }
        })()}>更多</Link>}
      </div>
      {data.moduleId === 5
        ? <Table
          dataSource={data.dataList}
          rowKey="id"
          loading={loading}
          pagination={false}
          className="module-content"
        >
          <Column dataIndex="date" title="日期" />
          <Column dataIndex="signIn" title="签到" />
          <Column dataIndex="signOut" title="签退" />
          <Column dataIndex="ask_text" title="请假外出" />
          <Column dataIndex="long" title="时长" />
        </Table>
        : <Table
          dataSource={data.dataList}
          rowKey="id"
          loading={loading}
          pagination={false}
          className="module-content"
        >
          <Column key="title" title="标题" width="55%" ellipsis={true} render={text => {
            switch (data.moduleId) {
              case 1:
                if (text.title == "您的审核需要补充资料" && text.type == 12 ) {
                  return newsObjNew[text.type] ?
                    <Link title={text.title} to={`${newsObjNew[text.type]}${text.targetId}&msgId=${text.id}`}>{text.title}</Link> :
                    <div onClick={() => onShowModal(text.id, text.title)}>{text.title}</div>
                } else {
                  return newsObj[text.type] ?
                    <Link title={text.title} to={`${newsObj[text.type]}${text.targetId}&msgId=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.targetId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link> :
                    <div onClick={() => onShowModal(text.id, text.title)}>{text.title}</div>
                }
              case 2:
                return urlObj[text.type] ?
                  <Link to={`${urlObj[text.type]}${text.targetId}&time=${+new Date()}&sign=${CryptoJS.MD5(text.targetId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.type == 15 ? `[${text.applyUser.name}] ${text.title} `: text.title}</Link> :
                  <div onClick={() => onSchedule(text.title)}>{text.type == 15 ? `[${text.applyUser.name}] ${text.title} `: text.title}</div>
              case 3:
                return <Link to={`/notice/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>
              case 4:
                return <Link to={`/regulation/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>
              case 6:
                return <Link to={`/document/details?id=${text.id}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text.title}</Link>
              default:
                return text.title
            }
          }} />
          {data.moduleId == 2 && <Column dataIndex="applyUser.name" title="申请人" ellipsis={true} width="20%" />}
          <Column dataIndex="addTime" title="创建时间" ellipsis={true} width="25%" />
        </Table>
      }
    </div>
  )
}

export default Module

/**
 * 模块名称: 反馈列表详情页
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import { parseSearch } from '@/utils'
import DetailsComp from '@/components/Askdetails'
import urls from '@/api/urls'
import api from '@/api'
import { 
  Radio,
  Icon 
} from 'antd'

const FeedbackDetail = (props) => {
  const { history } = props
  const search = parseSearch(props.location.search)
  const [radioFlag, setRadioFlag] = useState(false)//eslint-disable-line
  const [showRadio, setshowRadio ] = useState(false)
  const [isUserFul, setIsUserFul] = useState('')
  const [supportId, setSupportId] = useState('')
  const [dataTop, setDataTop] = useState({
    id: '',
    title: '',
    content: '',
  })
  const [dataBottom, setDataBottom] = useState({
    id: '',
    content: '',
    file: []
  })

  useEffect(() => {
    SupportFeedbackDetail()
    FeedbackReplyDetail()
  }, [])

  const SupportFeedbackDetail = () => {
    api.getSupportFeedbackDetail({ id: search.id }, true).then(data => {
      if (data.isFullAuth == '0') {//eslint-disable-line
        setshowRadio(true)
      }
      setDataTop({ 
        ...dataTop,
        title: data.title,  
        content: data.content,
      })
    })
  }

  const FeedbackReplyDetail = () => {
    api.getFeedbackReplyDetail({ id: search.id }, true).then(data => {
      if (search.msgId) {
        api.setMessageReaded({ ids: [search.msgId] })
      }
      setSupportId(data.fid)
      if(JSON.stringify(data.isUserFul)) {
        // console.log("data.isUserFul",(data.isUserFul)-1+'')
        setIsUserFul((data.isUserFul)-1+'')
      }
      setDataBottom({ 
        ...dataBottom,  
        content: data.replyContent,
        file: data.file
      })
    })
  }
 
  const onChangeRadio = (e) => {
    api.setSupportFeedbackUseful({
      supportFeedbackReplyId: supportId ? supportId : '' ,
      isUseFul: e.target.value,
    }).then((data) => {
      // setRadioFlag(true)
      FeedbackReplyDetail()
    })
  }

  // const onCancel = () => {
  //   history.push({
  //     pathname: '/helplist',
  //     state: { 'tabActiveKey': '2' }
  //   })
  // }

  return (
    <>
      <DetailsComp
        data={dataTop}
        dataTwo={dataBottom}
        onClose={history.goBack}
        url={dataBottom.file && dataBottom.file.length ? `${urls.downloadFeedbackAttachment.url}?id=${supportId}` : null}
      />
      {supportId ?
      <>
        <div className="help-detail-con">
          { showRadio ?
          <div className="help-detail">
            <Radio.Group value={isUserFul} size="large" onChange={onChangeRadio} disabled={radioFlag}>
              <Radio.Button value={"0"}><Icon type="like" /></Radio.Button>
              <Radio.Button value={"1"}><Icon type="dislike" /></Radio.Button>
            </Radio.Group>
          </div> : null
          }
        </div> 
      </> : null  
      }       
    </>
  )
}

export default FeedbackDetail

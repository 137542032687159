/**
 * 模块名称: 邮件日志
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
  Table,
  Form,
  DatePicker,
  Input,
  Button,
  Select,
  Alert,
  Popover
} from 'antd'
import { parseSearch } from '@/utils'
import Export from '@/components/Export'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  sort: '', //addTime
  sortMethod: '' //desc
}
let selectedIds = ''

let statusData = ['发送失败', '已发送', '待发送']

const MailLogs = props => {

  const { getFieldDecorator, validateFields, resetFields } = props.form
  const { history, match } = props

  const productsRef = useRef()

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [selectedCount, setSelectedCount] = useState(0)

  const intervalRef = useRef()
  const search = parseSearch(props.location.search)

  useEffect(() => {

    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogMailList()
    return () => clearTimeout(intervalRef.current)
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  //获取登录日志列表
  const getLogMailList = () => {
    let id = setTimeout(() => {
      api.getLogMailList(searchParamsTmp).then(res => {
        //console.log(res)
        setList(res.list)
        setCount(res.count)
        setLoading(false)
      }).catch(() => { setLoading(false) })
    }, 500)
    intervalRef.current = id
  }

  //重置
  const onReset = () => {
    resetFields()
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      sort: '', //addTime
      sortMethod: '' //desc
    }
    getLogMailList()
  }

  //提交
  const formSubmit = (e) => {
    e.preventDefault()
    //currentPage = 1
    validateFields((err, vals) => {
      if (vals !== undefined) {
        if (vals.recevier !== undefined) searchParamsTmp.recevier = vals.recevier
        if (vals.title !== undefined) searchParamsTmp.title = vals.title
        if (vals.status !== undefined) searchParamsTmp.status = vals.status
        if (vals.sendTime !== undefined) {
          searchParamsTmp.sendTimeBegin = vals.sendTime ? vals.sendTime[0].format('YYYY-MM-DD') : ''
          searchParamsTmp.sendTimeEnd = vals.sendTime ? vals.sendTime[1].format('YYYY-MM-DD') : ''
        }
      }
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getLogMailList()
      }
    })
  }

  //点击分页
  const onChangeTable = (pagination, filters, sorter) => {
    if (sorter.columnKey) {
      searchParamsTmp.sort = sorter.columnKey
      searchParamsTmp.sortMethod = sorter.order === 'ascend' ? 'asc' : 'desc'
    } else {
      searchParamsTmp.sort = ''
      searchParamsTmp.sortMethod = ''
    }
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    searchParamsTmp.limit = pageSize
    searchParamsTmp.page = currentPage
    getLogMailList()
  }

  const onShowExportDialog = () => {
    setShowExport(true)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    }
  }

  return (
    <>
      <div className="search-doc-wrap search-office-wrap">
        <Form onSubmit={formSubmit}  {...formItemLayout}>
          <div className="form-box">
            <FormItem label="收件人">
              {getFieldDecorator('recevier', {
              })(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="主题">
              {getFieldDecorator('title')(<Input placeholder="请输入" />)}
            </FormItem>
            <FormItem label="状态">
              {getFieldDecorator('status', {
              })(<Select placeholder="请选择">
                {
                  statusData.map((v, i) => {
                    return <Option value={v} key={i}>{v}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="时间">
              {getFieldDecorator('sendTime', {
              })(<RangePicker allowClear={false} />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={onReset}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Auth auths={authList} code="export"> */}
          <Button type="primary" icon="upload" onClick={onShowExportDialog}>导出</Button>
        {/* </Auth> */}
      </div>
        <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{selectedCount}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
        <Table
          size="small"
          dataSource={list}
          rowKey="id"
          className="search-table"
          loading={loading}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          rowSelection={rowSelection}
          onChange={onChangeTable}
        >
          <Column title="收件人" key="recevier" render={(text, record) => (
            <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text.recevier}</Link>
          )} />
          <Column title="主题" dataIndex="title" />
          <Column title="失败次数" dataIndex="failed" />
          <Column title="失败原因" key="failedReason" width={150} ellipsis={true} render={(text, record) => (
            <Popover content={text.failedReason} title="失败原因">
              <span style={{ color: '#1890ff' }}>{text.failedReason}</span>
            </Popover>
          )} />
          <Column title="状态" dataIndex="status" />
          <Column title="时间" dataIndex="updateTime" sorter />
        </Table>
      </div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }} 
        tplUrl="getLogExportTpl"
        fieldsUrl="getLogTplItems"
        saveUrl="getLogSaveExportTpls"
        exportUrl="getLogExport"
        method="logmail"
        parame={{ ExportType: 'logmail', id: selectedIds, ...searchParamsTmp, module: 0 }}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(MailLogs)
/**
 * 模块名称: 导入管理模块
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Paper from './Paper'
import Tolead from './Tolead'
import './assets/style.scss'

const ImportDownIndex = ({ match, history }) => {

  return (
    <div>
      <Switch>
        <Route path={match.path + '/import'} component={Tolead} />
        <Route path={match.path + '/tolead'} component={Paper} />
        <Route component={Paper} />
      </Switch>
    </div>
  )
}

export default ImportDownIndex
/**
 * 模块名称: 凭证模板导出列表
 * @author xuxiaobo@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Button,
  Input,
  message,
  Row,
  Col,
  DatePicker,
  Radio
} from 'antd'
import { parseSearch } from 'utils'
import CorpFilter from 'components/CorpFilter'
import moment from 'moment'
import Auth from 'components/AuthMiddleware'

const FormItem = Form.Item
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1

let filter = {
  branch: '',
}

const ExportExcel = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
  const search = parseSearch(location.search)
  const [customerType, setCustomerType] = useState('zhixiao')
  const [selectWay, setSelectWay] = useState('1')
  const [disable1, setDisable1] = useState(false)
  const [disable2, setDisable2] = useState(true)
  const [disable3, setDisable3] = useState(true)
  const [detailData, setDetailData] = useState({})
  const [showTypechoose, setShowTypechoose] = useState(true)
  const [authList, setAuthList] = useState([])//按钮权限

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.pageSize ? +search.pageSize : 10
    // console.log('customerType:' + customerType)
    // console.log('selectWay:' + selectWay)

    setDetailData({
      bank1: undefined,
      data1: undefined,
      pass_date1: undefined,
      bank2: undefined,
      data2: undefined,
      pass_date2: undefined,
      bank3: undefined,
      data3: undefined,
      pass_date3: undefined,
    })

    // console.log(detailData)
    getPageAuth()

  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const onChangeCorp = (data) => {
    filter.branch = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
  }

  const onChangePicker1 = (data) => {
    if (data.length === 0) {
      filter.date_start = ''
      filter.date_end = ''
    } else {
      filter.date_start = moment(data[0]).format('YYYY-MM-DD')
      filter.date_end = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  const onChangePicker2 = (data) => {
    if (data.length === 0) {
      filter.pass_date_start = ''
      filter.pass_date_end = ''
    } else {
      filter.pass_date_start = moment(data[0]).format('YYYY-MM-DD')
      filter.pass_date_end = moment(data[1]).format('YYYY-MM-DD')
    }
  }

  //radio选择
  const onChooseRadio = (e) => {
    // console.log(e.target.value)
    setSelectWay(e.target.value + '')

    setDetailData({
      bank1: undefined,
      data1: undefined,
      pass_date1: undefined,
      bank2: undefined,
      data2: undefined,
      pass_date2: undefined,
      bank3: undefined,
      data3: undefined,
      pass_date3: undefined,
    })

    // console.log(detailData)

    if (e.target.value + '' == '1') {
      setDisable1(false)
      setDisable2(true)
      setDisable3(true)
      setShowTypechoose(true)
    }
    else if (e.target.value + '' == '2') {
      setDisable1(true)
      setDisable2(false)
      setDisable3(true)
      setShowTypechoose(true)
    }
    else {
      setDisable1(true)
      setDisable2(true)
      setDisable3(false)
      setShowTypechoose(false)
    }
  }

  //radio选择
  const onChooseRadioType = (e) => {
    // console.log(e.target.value)
    setCustomerType(e.target.value)
  }

  //导出
  const onDumnp = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
    // console.log("vals",vals)
      if (!err) {
        filter = { ...filter, ...vals }
        //文档：http://oawiki.372163.com/index.php?s=/15&page_id=1785
        //跳转到这个地址 /api/fortune/kingdeeTemplate/export
        let bank = ''
        if (selectWay + '' == '1') {
          bank = filter.bank1
        }
        else if (selectWay + '' == '2') {
          bank = filter.bank2
        }
        else {
          bank = filter.bank3
        }
        let url2 = '/api/fortune/kingdeeTemplate/export?branch=' + filter.branch + '&customerType=' + customerType + '&selectWay=' + selectWay + '&bank=' + bank + '&date_start=' + filter.date_start + '&date_end=' + filter.date_end + '&pass_date_start=' + filter.pass_date_start + '&pass_date_end=' + filter.pass_date_end
        // console.log(url2)
        //window.open(url2)
        api.getHost({})
          .then(url => {
            url2 !== '' ?
              window.open(url.url + url2) :
              message.error('导出失败', 3);
          })

      }
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-item-wrap">
        <div className="search-item">
          <Form {...formItemLayout} className="ant-advanced-search-formTwo">
            <Row gutter={24}>
              <Col span={22}>
                <FormItem label="数据范围（必填）">
                  <Radio.Group>
                    <Radio value="1" onClick={(e) => onChooseRadio(e)} checked={selectWay + '' == '1'} >当月到，当月认</Radio>
                    <Radio value="2" onClick={(e) => onChooseRadio(e)} checked={selectWay + '' == '2'}>当月认，以前到</Radio>
                    <Radio value="3" onClick={(e) => onChooseRadio(e)} checked={selectWay + '' == '3'}>当月到，未认款</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={1}>
              </Col>
              <Col span={1}>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={22}>
                {showTypechoose ?
                <FormItem label="类型（必填）">
                  <Radio.Group>
                    <Radio value="zhixiao" onClick={(e) => onChooseRadioType(e)} checked={customerType == 'zhixiao'}>直销</Radio>
                    <Radio value="qudao" onClick={(e) => onChooseRadioType(e)} checked={customerType == 'qudao'}>渠道</Radio>
                  </Radio.Group>
                </FormItem>
                : null}
              </Col>
              <Col span={1}>
              </Col>
              <Col span={1}>
              </Col>
            </Row>
          </Form>
          <div className="line"></div>
          <div className="white-line"></div>
          {
            selectWay + '' == '1' ?
              <Form {...formItemLayout} className="ant-advanced-search-formTwo">
                <Row gutter={24} style={selectWay + '' == '1' ? { backgroundColor: '#FFF8DC' } : null}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      {getFieldDecorator('bank1', { initialValue: detailData.bank1, rules: [{ required: true, message: '请输入到款银行' }] })(<Input placeholder="请输入" />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      {getFieldDecorator('date1', { initialValue: detailData.date1, rules: [{ required: true, message: '请选择到款日期' }] })(<RangePicker onChange={onChangePicker1} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      {getFieldDecorator('pass_date1', { initialValue: detailData.pass_date1, rules: [{ required: true, message: '请选择财务通过时间' }] })(<RangePicker onChange={onChangePicker2} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      <RangePicker onChange={onChangePicker2} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <div className="search-btns" style={{ width:'100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Auth auths={authList} code="operate">
                  <Button type="primary" size="large" onClick={onDumnp} style={{ marginLeft: 15 }}>导出</Button>
                </Auth>
                </div>
              </Form>
              : null
          }

          {
            selectWay + '' == '2' ?
              <Form {...formItemLayout} className="ant-advanced-search-formTwo">
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true}
                       />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      <RangePicker onChange={onChangePicker2} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24} style={selectWay + '' == '2' ? { backgroundColor: '#FFF8DC' } : null}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      {getFieldDecorator('bank2', { initialValue: detailData.bank2, rules: [{ required: true, message: '请输入到款银行' }] })(
                      <Input placeholder="请输入" />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      {getFieldDecorator('date2', { initialValue: detailData.date2, rules: [{ required: true, message: '请选择到款日期' }] })(<RangePicker onChange={onChangePicker1} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      {getFieldDecorator('pass_date2', { initialValue: detailData.pass_date2, rules: [{ required: true, message: '请选择财务通过时间' }] })(<RangePicker onChange={onChangePicker2} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <div className="search-btns" style={{ width:'100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                  <Auth auths={authList} code="operate">
                    <Button type="primary" size="large" onClick={onDumnp} style={{ marginLeft: 15 }}>导出</Button>
                  </Auth>
                </div>
              </Form>
              : null
          }

          {
            selectWay + '' == '3' ?
              <Form {...formItemLayout} className="ant-advanced-search-formTwo">
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      <RangePicker onChange={onChangePicker2} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      <Input placeholder="请输入" disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      <RangePicker onChange={onChangePicker1} disabled={true} />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="财务通过时间">
                      <RangePicker onChange={onChangePicker2} disabled={true} />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}  style={selectWay+'' == '3' ? {backgroundColor:'#FFF8DC'} : null}>
                  <Col span={8}>
                    <FormItem label="到款银行">
                      {getFieldDecorator('bank3', { initialValue: detailData.bank3, rules: [{ required: true, message: '请输入到款银行' }] })(<Input placeholder="请输入" disabled={false} />)}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="到款日期">
                      {getFieldDecorator('date3', { initialValue: detailData.date3, rules: [{ required: true, message: '请选择到款日期' }] })(<RangePicker onChange={onChangePicker1} disabled={false} />)}
                    </FormItem>
                  </Col>
                </Row>
                <div className="search-btns" style={{ width:'100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                  <Auth auths={authList} code="operate">
                    <Button type="primary" size="large" onClick={onDumnp} style={{ marginLeft: 15 }}>导出</Button>
                  </Auth>
                </div>
              </Form>
              : null
          }
          {/* <Form {...formItemLayout} className="ant-advanced-search-formTwo">
            <Row gutter={24} style={selectWay+'' == '1' ? {backgroundColor:'#FFF8DC'} : null}>
              <Col span={8}>
                <FormItem label="到款银行">
                  {getFieldDecorator('bank1', { initialValue: detailData.bank1, rules: [{ required: selectWay+'' == '1', message: '请输入到款银行'}]})(<Input placeholder="请输入" disabled={disable1} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="到款日期">
                  {getFieldDecorator('date1', { initialValue: detailData.date1, rules: [{ required: selectWay+'' == '1', message: '请选择到款日期'}]})(<RangePicker onChange={onChangePicker1} disabled={disable1} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="财务通过时间">
                  {getFieldDecorator('pass_date1', { initialValue: detailData.pass_date1, rules: [{ required: selectWay+'' == '1', message: '请选择财务通过时间'}]})(<RangePicker onChange={onChangePicker2} disabled={disable1} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} style={selectWay+'' == '2' ? {backgroundColor:'#FFF8DC'} : null}>
              <Col span={8}>
                <FormItem label="到款银行">
                  {getFieldDecorator('bank2', { initialValue: detailData.bank2, rules: [{ required: selectWay+'' == '2', message: '请输入到款银行'}] })(<Input placeholder="请输入" disabled={disable2} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="到款日期">
                  {getFieldDecorator('date2', { initialValue: detailData.date2, rules: [{ required: selectWay+'' == '2', message: '请选择到款日期'}]})(<RangePicker onChange={onChangePicker1} disabled={disable2}  />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="财务通过时间">
                  {getFieldDecorator('pass_date2', { initialValue: detailData.pass_date2, rules: [{ required: selectWay+'' == '2', message: '请选择财务通过时间'}]})(<RangePicker onChange={onChangePicker2} disabled={disable2}  />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} style={selectWay+'' == '3' ? {backgroundColor:'#FFF8DC'} : null}>
              <Col span={8}>
                <FormItem label="到款银行">
                  {getFieldDecorator('bank3', { initialValue: detailData.bank3, rules: [{ required: selectWay+'' == '3', message: '请输入到款银行'}] })(<Input placeholder="请输入" disabled={disable3}  />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="到款日期">
                  {getFieldDecorator('date3', { initialValue: detailData.date3, rules: [{ required: selectWay+'' == '3', message: '请选择到款日期'}]})(<RangePicker onChange={onChangePicker1} disabled={disable3} />)}
                </FormItem>
              </Col>
            </Row>
            <div className="search-btns" style={{align:'center'}}>
              <Auth auths={authList} code="operate">
                <Button type="primary" size="large" onClick={onDumnp} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
            </div>
            </Form> */}
        </div>
      </div>
    </>
  )
}

export default Form.create()(ExportExcel)
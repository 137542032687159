/**
 * 模块名称: 后台首页
 * @author xuzhongyuan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import Module from './Module'
import { message, Modal, Button, Icon, Drawer } from 'antd'
import './assets/style.scss'
import { bubbleSort } from '@/utils'
import { useSelector, useDispatch } from 'react-redux'
import { reloadEnd } from '@/global/reducer/reload'
import CenterPassword from '@/components/Layout/CenterPassword'
import Tips from './IndexTips'
// import Tables from './Tables'

// let today = new Date(),
// date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
// dates = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate() - 1)

const Index = () => {
  const [modules, setModules] = useState([])
  const reload = useSelector(state => state.reload)
  const userInfo = useSelector(state => state.userInfo)
  const [showTips, setShowTips] = useState(false)
  const [visible, setVisible] = useState(false)
  const [passVisible, setPassVisible] = useState(false)
  // const [dateModal, setDateModal] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // api.getUser().then(res => {
    //   if (localStorage.getItem(`${res.staffNo}&${date}`) == 1) {
    //     setDateModal(false)
    //   } else {
    //     setDateModal(true)
    //     localStorage.setItem(`${res.staffNo}&${date}`, 1)
    //     localStorage.removeItem(`${res.staffNo}&${dates}`)
    //   }
    // })
    getIndexData()
    let timer = setTimeout(() => {
      setShowTips(true)
      clearTimeout(timer)
    }, 2000)
  }, [])

  useEffect(() => {
    if (reload) {
      getIndexData()
    }
  }, [reload])

  const getIndexData = () => {
    api.getIndex(true).then(data => {
      setModules(bubbleSort(data.list))
      setVisible(data.modifyPassword)
      dispatch(reloadEnd())
    })
  }

  const onChangeModule = (moveIndex, targetIndex) => {
    // console.log('moveIndex 拖动元素', moveIndex)
    // console.log('targetIndex 被交换元素', targetIndex)
    const data = modules.splice(moveIndex, 1)[0]
    modules.splice(targetIndex, 0, data)
    const newModules = modules.map((item, index) => ({ ...item, sort: index }))
    api.dragDort(newModules.map(item => ({ moduleId: item.moduleId, sort: item.sort }))).then(() => {
      setModules(newModules)
    })
  }

  const handleOk = () => {
    setVisible(false)
    setPassVisible(true)
  }

  const onPassSave = (data) => {
    // console.log("data=>", data)
    api.getLoginUpdatePwd(data).then(() => {
      setPassVisible(false)
      message.success('密码修改成功，再次登录时，请使用新登录密码', 3)
    }).catch(() => setPassVisible(true))
  }

  return (
    <>
      {userInfo.isMainAccount !== 1 && showTips && <Tips />}
      {/* {userInfo.isMainAccount !== 1 ? null : <section className="modules-wrap">
        {modules.map(item => <Module key={item.moduleId} data={item} onChange={onChangeModule} getIndexData={getIndexData} />)}
      </section>} */}
      <section className="modules-wrap">
        {modules.map(item => <Module key={item.moduleId} data={item} onChange={onChangeModule} getIndexData={getIndexData} />)}
      </section>
      {/* {userInfo.saleOrServ && showTips && dateModal && <Tables />} */}
      <Modal
        title={<div style={{ display: 'flex'}}>
          <Icon 
            type="exclamation-circle" 
            theme="filled" 
            style={{ color: '#F39F18', fontSize: '26px', marginRight: '12px', marginTop: '-2px' }}
          />
          <div style={{ fontWeight: '800', fontSize: '16px' }}>该密码已使用超过90天，为了您的账户安全，建议您及时修改登录密码!</div>
        </div>}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        maskClosable={false}
        closable={false}
        okText="确认"
      >
        <p>密码规则: 7-20位字符，不能为纯数字，不能含有空格，必须同时包含大小写字母、数字</p>
      </Modal>
      <Drawer
        title="修改密码"
        width={600}
        onClose={() => setPassVisible(false)}
        visible={passVisible}
        destroyOnClose={true}
      >
        <CenterPassword onCancelPassEdit={() => setPassVisible(false)} onPassSave={onPassSave} />
      </Drawer>
    </>
  )
}

export default Index